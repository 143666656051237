import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import { pageTitle, PcToast } from '@je-pc/ui-components'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import { initialiseLogging, logError } from './logging'

Vue.use(PcToast, {
  compact: true,
  closeButton: null
})
Vue.mixin(pageTitle)

initialiseLogging()
Vue.config.errorHandler = error => {
  logError(error)
}

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa
        }
      })
    },
    router,
    i18n,
    store
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
