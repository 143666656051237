<template>
  <div class="items-list-group" data-cy="itemsListGroup">
    <!-- group info -->
    <div class="mt-6">
      <!-- group base info -->
      <div
        class="flex px-4 md:px-0 items-center"
        :class="{ 'text-grey-45': !isSelectedOnlineFilter }"
      >
        <pc-checkbox
          :label="getMarkedString(group.name)"
          v-model="isGroupSelected"
          class="font-bold"
          data-cy="itemsListGroupPicker"
          @change="trackGroupSelection"
        />
        <items-list-badge
          :label="groupStatusBadge"
          :class="[
            allIndefinitelyOfflineProducts ? 'bg-yellow-10' : 'bg-grey-20'
          ]"
          class="ml-2"
          data-cy="itemsListGroupStatusBadge"
        />
        <items-list-badge
          :label="groupServiceBadge"
          class="bg-blue-10 ml-6 sm:ml-3 mt-2 sm:mt-0"
          data-cy="itemsListGroupServiceBadge"
        />
        <div
          v-if="group.hasSingleProduct"
          class="ml-auto"
          data-cy="itemsListGroupPrice"
        >
          {{ $n(products[0].price, 'currency') }}
        </div>
      </div>
      <!-- group description -->
      <div
        v-if="group.description"
        v-html="getMarkedString(group.description)"
        class="text-sm pl-8 ml-4 md:ml-0"
        :class="!isSelectedOnlineFilter ? 'text-grey-45' : 'text-grey-50'"
        data-cy="itemsListGroupDescription"
      ></div>
    </div>

    <!-- list of group products -->
    <div v-if="!group.hasSingleProduct" class="px-4 md:px-0">
      <items-list-group-product
        v-for="product in products"
        v-show="product.isVisible"
        :key="product.id"
        :product="product"
        :is-visible-status-type="!groupStatusBadge"
        :is-visible-service-type="group.hasMixedServiceType"
      />
    </div>
    <!-- group line -->
    <hr class="w-full my-6 border-grey-30" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { trackSelection } from '../../services/gtm'
import {
  ADD_TO_SELECTED_PRODUCTS,
  REMOVE_FROM_SELECTED_PRODUCTS
} from '../../store/mutations.types'
import ProductMixin from '../../mixins/product'
import SearchMarkMixin from '../../mixins/searchMark'
import ItemsListGroupProduct from './ItemsListGroupProduct'

export default {
  mixins: [ProductMixin, SearchMarkMixin],
  props: {
    group: {
      required: true
    }
  },
  components: {
    ItemsListGroupProduct
  },
  computed: {
    ...mapState({
      isSelectedOnlineFilter: 'isSelectedOnlineFilter'
    }),
    products() {
      return this.group?.products
    },
    visibleProducts() {
      if (!this.group.isVisible) return []
      return this.products.filter(product => product.isVisible)
    },
    visibleProductIds() {
      return this.visibleProducts.map(product => product.id)
    },
    isGroupSelected: {
      get() {
        return this.visibleProducts.every(product => product.isSelected)
      },
      set(isSelected) {
        isSelected
          ? this.$store.commit(ADD_TO_SELECTED_PRODUCTS, this.visibleProductIds)
          : this.$store.commit(
              REMOVE_FROM_SELECTED_PRODUCTS,
              this.visibleProductIds
            )
      }
    },
    offlineProducts() {
      return this.products.filter(product => product.isOffline)
    },
    hasIndefinitelyOfflineProducts() {
      return this.offlineProducts.some(product => product.isIndefinitelyOffline)
    },
    allIndefinitelyOfflineProducts() {
      return this.offlineProducts.every(
        product => product.isIndefinitelyOffline
      )
    },
    //group badges logic
    groupServiceBadge() {
      const [product] = this.products
      if (
        this.group.hasMixedServiceType ||
        !this.menuHasMixedServiceTypes ||
        product.hasMixedServiceTypes
      )
        return ''
      return this.$t(product.serviceTypes[0])
    },
    groupStatusBadge() {
      if (this.isSelectedOnlineFilter) return ''
      if (!this.hasIndefinitelyOfflineProducts) return this.$t('back_tomorrow')
      if (this.allIndefinitelyOfflineProducts)
        return this.$t('off_menu_indefinitely')
      return ''
    }
  },
  methods: {
    trackGroupSelection(value) {
      trackSelection(value, this.group.id)
    }
  }
}
</script>
