export default class Availability {
  static delivery = 'delivery'
  static collection = 'collection'
  static serviceTypeApiMapper = {
    [Availability.delivery]: 'delivery',
    [Availability.collection]: 'collection'
  }
  constructor(availability) {
    this.id = availability.id
    this.availability = availability.serviceTypes.map(serviceType => {
      return this.getServiceTypeApiKeyToUiKey(serviceType)
    })
  }
  get serviceTypes() {
    return this.availability.filter(serviceType =>
      Object.keys(Availability.serviceTypeApiMapper).includes(serviceType)
    )
  }
  getServiceTypeApiKeyToUiKey(apiKey) {
    const uiModelKey = Object.keys(Availability.serviceTypeApiMapper).find(
      key => Availability.serviceTypeApiMapper[key] === apiKey
    )
    return uiModelKey || apiKey
  }
}
