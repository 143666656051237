<template>
  <div class="flex items-center relative mb-6" data-cy="heading">
    <div class="pr-4 w-full lg:w-2/3">
      <h1 class="hidden sm:block mb-6 leading-none font-bold text-3xl">
        {{ $t('item_availability') }}
      </h1>
      <p>
        <!-- base item availability description -->
        {{ $t('heading_description') }}
        <!-- edit menu by rmm or form -->
        <template
          v-if="isMenuEditingToolsEnabled"
          data-cy="headingChangeMenuText"
        >
          <i18n
            :path="
              isMenuManagementEnabled
                ? 'heading_menu_edit_rmm'
                : 'heading_menu_edit_form'
            "
            tag="span"
          >
            <pc-global-link
              :to="menuEditUrl"
              class="text-blue-100 font-bold hover:underline"
            >
              {{
                $t(
                  isMenuManagementEnabled
                    ? 'heading_menu_edit_rmm_link'
                    : 'heading_menu_edit_form_link'
                )
              }}
            </pc-global-link>
          </i18n>
        </template>
        <!-- edit menu by email if menu editing is disabled -->
        <template v-else data-cy="headingChangeMenuText">
          {{ $t('heading_menu_edit_by_email') }}
        </template>
      </p>
    </div>
    <icon-hero class="hidden lg:block absolute right-0 bottom-0 h-auto" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { PcGlobalLink } from '@je-pc/ui-components'
import {
  MENU_MANAGEMENT_URL,
  MENU_FORM_EDIT_URL,
  MENU_EDITING_TOOLS_ENABLED
} from '../constants'
import IconHero from '../icons/IconHero'

export default {
  components: {
    PcGlobalLink,
    IconHero
  },
  computed: {
    ...mapState({
      menuManagementVersion: state =>
        state.settings.restaurantMenuManagementVersion
    }),
    isMenuEditingToolsEnabled() {
      return MENU_EDITING_TOOLS_ENABLED
    },
    isMenuManagementEnabled() {
      return Boolean(this.menuManagementVersion)
    },
    menuEditUrl() {
      if (!this.menuManagementVersion) return MENU_FORM_EDIT_URL
      return MENU_MANAGEMENT_URL[this.menuManagementVersion]
    }
  }
}
</script>
