export const TENANT = 'uk'
export const SMARTGATEWAY_URL = 'https://smartgateway-qa28.cogpart.je-labs.com'
export const MENU_EDITING_TOOLS_ENABLED = 'true'
export const MENU_MANAGEMENT_URL = {
v1: 'https://uk-menuselfserviceweb-qa28.cogpart.je-labs.com',
  v2: 'https://uk-partnercentrespa-qa28.cogpart.je-labs.com/settings/menu'
}
export const MENU_FORM_EDIT_URL = 'https://uk-partnerrestaurantdetailsweb-qa28.cogpart.je-labs.com/edit-menu'
export const LOGGING_URL = 'https://logging-app.pe-staging.je-labs.com'
export const FEATURE = 'pcmoduleitemavailability'
export const ENVIRONMENT = 'qa28'
