<template>
  <div
    class="
        flex flex-col md:flex-row
        md:justify-end justify-items-auto md:items-center"
    data-cy="itemsFilter"
  >
    <div class="font-bold mr-4 mb-2 md:mb-0">{{ $t('view_items') }}:</div>
    <div class="flex space-x-2">
      <button
        v-for="(filter, index) in filtersList"
        :key="index"
        @click="isSelectedOnlineFilter = filter.isOnline"
        class="text-center leading-snug border font-bold px-4 py-1 rounded-2xl cursor-pointer space-x-2 focus:outline-none focus:shadow-outline-button"
        :class="getActiveClasses(filter.isOnline)"
        :data-cy="`itemsFilterButton ${getLocatorName(filter.isOnline)}`"
      >
        {{ $t(filter.title) }}
      </button>
    </div>
  </div>
</template>

<script>
import {
  SET_IS_SELECTED_ONLINE_FILTER,
  RESET_SELECTED_PRODUCTS
} from '../store/mutations.types'

export default {
  computed: {
    filtersList() {
      return [
        {
          isOnline: true,
          title: 'filter_on_menu'
        },
        {
          isOnline: false,
          title: 'filter_off_menu'
        }
      ]
    },
    isSelectedOnlineFilter: {
      get() {
        return this.$store.state.isSelectedOnlineFilter
      },
      set(value) {
        if (value === this.isSelectedOnlineFilter) {
          return
        }
        this.$store.commit(SET_IS_SELECTED_ONLINE_FILTER, value)
        this.$store.commit(RESET_SELECTED_PRODUCTS)
      }
    }
  },
  methods: {
    getLocatorName(value) {
      const type = value ? 'isOnline' : 'isOfflne'
      return `itemsFilterType${type}`
    },
    getActiveClasses(value) {
      return this.isSelectedOnlineFilter === value
        ? 'bg-blue-100 border-blue-100 text-white'
        : 'bh-white border-grey-40'
    }
  }
}
</script>
