import { mapState, mapGetters } from 'vuex'
import { PcCheckbox } from '@je-pc/ui-components'
import ItemsListBadge from '../components/ItemsList/ItemsListBadge'

export default {
  components: {
    PcCheckbox,
    ItemsListBadge
  },
  computed: {
    ...mapState({
      selectedProductIds: 'selectedProductIds'
    }),
    ...mapGetters({
      menuHasMixedServiceTypes: 'menuHasMixedServiceTypes',
      offlineProductIds: 'offlineProductIds',
      indefinitelyOfflineProductIds: 'indefinitelyOfflineProductIds'
    })
  }
}
