import Vue from 'vue'
import i18n from '../i18n'

const userMessageService = params => Vue.notify(params)

export const successHandler = ({ message }) => {
  userMessageService({ type: 'success', message })
}
export const errorHandler = ({ message } = {}) => {
  const errMessage = message || i18n.t('generic_error')
  userMessageService({ type: 'error', message: errMessage })
}
