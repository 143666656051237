export const SET_MENU_CATEGORIES = 'setMenuItems'
export const SET_ACTIVE_CATEGORY_ID = 'setActiveCategoryId'
export const ADD_TO_OFFLINE_PRODUCTS = 'addToOfflineProducts'
export const REMOVE_FROM_OFFLINE_PRODUCTS = 'removeFromOfflineProducts'
export const SET_SETTINGS = 'setSettings'
export const ADD_TO_SELECTED_PRODUCTS = 'addToSelectedProducts'
export const REMOVE_FROM_SELECTED_PRODUCTS = 'removeFromSelectedProducts'
export const RESET_SELECTED_PRODUCTS = 'resetSelectedProducts'
export const SET_IS_SELECTED_ONLINE_FILTER = 'setIsSelectedOnlineFilter'
export const SET_SEARCH_VALUE = 'setSearchValue'
export const SET_DELETED_PRODUCTS = 'setDeletedProducts'
