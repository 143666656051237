<template>
  <div class="container" data-cy="itemAvailabilityHome">
    <skeleton-loader v-if="isLoading" />
    <template v-else>
      <!-- page heading -->
      <heading />
      <hr class="hidden lg:block md:my-6 border-grey-40" />
      <!-- page content -->
      <div class="relative min-h-screen flex" v-if="categories">
        <!-- page sidebar -->
        <aside class="hidden lg:block w-1/4">
          <categories-list class="mr-8 sticky top-0" />
        </aside>
        <!-- page main content -->
        <main
          class="w-full lg:w-3/4"
          sticky-container
          :class="{ 'bg-white': !isMobile }"
        >
          <!-- search and control content -->
          <div
            class="flex w-full flex-row sm:px-4 lg:px-12 sm:py-5"
            v-sticky
            :sticky-full="isMobile"
            sticky-classes="fixed top-0 bg-white shadow z-10"
          >
            <items-search class="flex sm:w-4/12" />
            <items-control class="flex sm:w-8/12" />
          </div>
          <hr class="hidden sm:block border-grey-30 sm:mx-4 lg:mx-12" />
          <!-- Select all and filters content -->
          <div class="flex flex-col md:flex-row-reverse sm:px-4 lg:px-12 py-5">
            <!-- filters -->
            <items-filter class="w-full md:w-8/12 lg:w-9/12" />
            <hr class="w-full md:hidden border-grey-30 my-5" />
            <items-select-all
              v-if="!isEmptyVisibleList"
              class="w-full md:w-4/12 lg:w-3/12 mb-4 md:mb-0"
            />
          </div>
          <hr class="hidden md:block border-grey-30" />
          <!-- list content-->
          <items-list-empty-state v-if="isEmptyVisibleList" />
          <items-list :class="{ 'list-offset': isMobileControlVisible }" />
        </main>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { isMobile } from '@je-pc/ui-components'
import { FETCH_ITEM_AVAILABILITY } from '../store/actions.types'
import { errorHandler } from '../services/responseHandler'
import sticky from '../directives/sticky'
import Heading from '../components/Heading'
import CategoriesList from '../components/CategoriesList'
import ItemsSearch from '../components/ItemsSearch'
import ItemsControl from '../components/ItemsControl/ItemsControl'
import ItemsSelectAll from '../components/ItemsSelectAll'
import ItemsFilter from '../components/ItemsFilter'
import ItemsList from '../components/ItemsList/ItemsList'
import SkeletonLoader from '../components/SkeletonLoader'
import ItemsListEmptyState from '../components/ItemsList/ItemsListEmptyState'

export default {
  components: {
    Heading,
    CategoriesList,
    ItemsControl,
    ItemsSearch,
    ItemsSelectAll,
    ItemsFilter,
    ItemsList,
    SkeletonLoader,
    ItemsListEmptyState
  },
  mixins: [isMobile],
  title() {
    return this.$t('item_availability')
  },
  directives: {
    sticky
  },
  data: () => ({
    isLoading: false
  }),
  async created() {
    this.isLoading = true
    try {
      await this.$store.dispatch(FETCH_ITEM_AVAILABILITY)
    } catch (error) {
      errorHandler()
    }
    this.isLoading = false
  },
  computed: {
    ...mapState({
      categories: 'categories'
    }),
    ...mapGetters({
      isProductsSelected: 'isProductsSelected',
      visibleCategories: 'visibleCategories'
    }),
    isEmptyVisibleList() {
      return this.visibleCategories.length === 0
    },
    isMobileControlVisible() {
      return this.isMobile && this.isProductsSelected
    }
  }
}
</script>

<style scoped>
.list-offset {
  margin-bottom: 7.5rem;
}
</style>
