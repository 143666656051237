import { trackCustomEvent } from '@je-pc/tracking'

export const trackItemsInteraction = label => {
  trackCustomEvent('trackEvent', {
    eventCategory: 'engagement',
    eventAction: 'itemAvailability',
    eventLabel: label
  })
}

export const trackSelection = (isSelected, id) => {
  if (!isSelected) {
    return
  }

  const label = `select_item_${id}`
  trackItemsInteraction(label)
}
