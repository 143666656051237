import Vue from 'vue'
import Vuex from 'vuex'
import { union } from 'lodash'
// http service methods
import {
  fetchItemAvailability,
  getOfflineItems,
  getSettings,
  markItemsAsOnline,
  markItemsAsOffline,
  deleteItems
} from '../services/http'
// tracking methods
import { trackItemsInteraction } from '../services/gtm'
// store mutations types
import {
  SET_MENU_CATEGORIES,
  SET_ACTIVE_CATEGORY_ID,
  SET_SETTINGS,
  ADD_TO_SELECTED_PRODUCTS,
  REMOVE_FROM_SELECTED_PRODUCTS,
  RESET_SELECTED_PRODUCTS,
  SET_IS_SELECTED_ONLINE_FILTER,
  ADD_TO_OFFLINE_PRODUCTS,
  REMOVE_FROM_OFFLINE_PRODUCTS,
  SET_SEARCH_VALUE,
  SET_DELETED_PRODUCTS
} from './mutations.types'
// store actions types
import {
  FETCH_ITEM_AVAILABILITY,
  LOAD_MENU_DATA,
  LOAD_SETTINGS,
  MARK_ITEMS_AS_ONLINE,
  MARK_ITEMS_AS_OFFLINE,
  DELETE_ITEMS
} from './actions.types'

import { getNextAvailableAtDate } from '../services/availability'

Vue.use(Vuex)

const SETTINGS_KEYS = ['restaurantMenuManagementVersion', 'isMenuSyncEnabled']

export default new Vuex.Store({
  state: {
    activeCategoryId: null,
    selectedProductIds: [],
    settings: {
      restaurantMenuManagementVersion: false,
      isMenuSyncEnabled: false,
      isLoaded: false
    },
    categories: null,
    offlineProducts: [],
    deletedProductIds: [],
    isSelectedOnlineFilter: true,
    searchValue: ''
  },
  getters: {
    isProductsSelected(state) {
      return state.selectedProductIds.length !== 0
    },
    searchRegex(state) {
      return new RegExp(state.searchValue.replace(/[^a-zA-Z0-9]/g, ''), 'i')
    },
    productsList(state) {
      if (!state.categories) return []
      return Object.values(state.categories).flatMap(category =>
        category.productsGroups.flatMap(group => group.products)
      )
    },
    preparedProductList(state, getters) {
      const {
        deletedProductIds,
        isSelectedOnlineFilter,
        selectedProductIds
      } = state
      const {
        offlineProductIds,
        indefinitelyOfflineProductIds,
        searchRegex
      } = getters
      const products = getters.productsList
      products.forEach(product => {
        const { id } = product
        product.isOffline = offlineProductIds.includes(id)
        product.isIndefinitelyOffline = indefinitelyOfflineProductIds.includes(
          id
        )
        product.isDeleted = product.isDeleted || deletedProductIds.includes(id)
        product.isSelected = selectedProductIds.includes(id)
        product.isVisible =
          !product.isDeleted &&
          isSelectedOnlineFilter === !product.isOffline &&
          searchRegex.test(product.meta)
            ? true
            : false
      })
      return products
    },
    filteredCategories(state, getters) {
      const categories = state.categories
      const products = getters.preparedProductList
      if (!categories || !products) return []
      const categoriesArray = Object.values(categories)
      categoriesArray.forEach(category => {
        let categoryIsVisible = false
        category.productsGroups.forEach(group => {
          group.isVisible = group.products.some(product => product.isVisible)
          if (!categoryIsVisible) {
            categoryIsVisible = group.isVisible
          }
        })
        category.isVisible = categoryIsVisible
      })
      return categoriesArray
    },
    visibleCategories(state, getters) {
      return getters.filteredCategories.filter(category => category.isVisible)
    },
    visibleProductIds(state, getters) {
      return getters.visibleCategories
        .flatMap(category =>
          category.productsGroups.flatMap(group => group.products)
        )
        .filter(item => item.isVisible)
        .map(item => item.id)
    },
    offlineProductIds(state) {
      return state.offlineProducts.map(product => product.id)
    },
    indefinitelyOfflineProductIds(state) {
      return state.offlineProducts
        .filter(product => !product.nextAvailableAt)
        .map(product => product.id)
    },
    menuHasMixedServiceTypes(state, getters) {
      if (!getters.productsList.length) return false
      return getters.productsList.some(product => product.hasMixedServiceTypes)
    }
  },
  mutations: {
    [SET_ACTIVE_CATEGORY_ID](state, id) {
      state.activeCategoryId = id
    },
    [SET_MENU_CATEGORIES](state, categories) {
      if (!categories || Object.keys(categories).length === 0) return
      state.categories = categories
    },
    [SET_SETTINGS](state, settings) {
      state.settings = settings
    },
    [ADD_TO_SELECTED_PRODUCTS](state, productIds = []) {
      state.selectedProductIds = union(state.selectedProductIds, productIds)
    },
    [REMOVE_FROM_SELECTED_PRODUCTS](state, productIds = []) {
      state.selectedProductIds = state.selectedProductIds.filter(
        id => !productIds.includes(id)
      )
    },
    [RESET_SELECTED_PRODUCTS](state) {
      state.selectedProductIds = []
    },
    [SET_IS_SELECTED_ONLINE_FILTER](state, value) {
      state.isSelectedOnlineFilter = value
    },
    [ADD_TO_OFFLINE_PRODUCTS](state, products = []) {
      products.forEach(product => {
        const { id, nextAvailableAt } = product
        state.offlineProducts.push({ id, nextAvailableAt })
      })
    },
    [REMOVE_FROM_OFFLINE_PRODUCTS](state, productIds = []) {
      state.offlineProducts = state.offlineProducts.filter(
        product => !productIds.includes(product.id)
      )
    },
    [SET_SEARCH_VALUE](state, value) {
      state.searchValue = value
    },
    [SET_DELETED_PRODUCTS](state) {
      state.deletedProductIds.push(...state.selectedProductIds)
    }
  },
  actions: {
    async [FETCH_ITEM_AVAILABILITY]({ dispatch }) {
      try {
        await Promise.all([dispatch(LOAD_SETTINGS), dispatch(LOAD_MENU_DATA)])
      } catch (error) {
        throw new Error(error)
      }
    },
    async [LOAD_MENU_DATA]({ commit }) {
      try {
        const [menuCategoriesData, offlineItems] = await Promise.all([
          fetchItemAvailability(),
          getOfflineItems()
        ])
        // menu offline items
        commit(ADD_TO_OFFLINE_PRODUCTS, offlineItems)
        // menu categories
        commit(SET_MENU_CATEGORIES, menuCategoriesData)
      } catch (error) {
        throw new Error(error)
      }
    },
    async [LOAD_SETTINGS]({ state, commit }) {
      try {
        if (state.settings.isLoaded) return
        const response = await getSettings(SETTINGS_KEYS.join(','))
        const { settings } = response.data
        if (!settings) return
        commit(SET_SETTINGS, {
          restaurantMenuManagementVersion:
            settings.restaurantMenuManagementVersion,
          isMenuSyncEnabled: settings.isMenuSyncEnabled === 'true',
          isLoaded: true
        })
      } catch (error) {
        throw new Error(error)
      }
    },
    async [MARK_ITEMS_AS_ONLINE]({ state, commit }) {
      const { selectedProductIds } = state
      try {
        await markItemsAsOnline(selectedProductIds)
        trackItemsInteraction(`make_item_online_${selectedProductIds.length}`)
        commit(REMOVE_FROM_OFFLINE_PRODUCTS, selectedProductIds)
        commit(RESET_SELECTED_PRODUCTS)
      } catch (error) {
        throw new Error(error)
      }
    },
    async [MARK_ITEMS_AS_OFFLINE]({ state, getters, commit }) {
      try {
        const nextAvailableAt = getNextAvailableAtDate()
        const { selectedProductIds } = state
        await markItemsAsOffline(selectedProductIds, nextAvailableAt)
        const { offlineProductIds } = getters
        // collect only items which are not in offline list already
        const uniqProducts = selectedProductIds.reduce((result, pId) => {
          if (!offlineProductIds.includes(pId)) {
            result.push({ id: pId, nextAvailableAt })
          }
          return result
        }, [])
        trackItemsInteraction(`make_item_offline_${selectedProductIds.length}`)
        commit(ADD_TO_OFFLINE_PRODUCTS, uniqProducts)
        commit(RESET_SELECTED_PRODUCTS)
      } catch (error) {
        throw new Error(error)
      }
    },
    async [DELETE_ITEMS]({ state, commit }) {
      const { selectedProductIds } = state
      const params = {
        data: { variationIds: selectedProductIds }
      }
      try {
        await deleteItems(params)
        trackItemsInteraction(`delete_item_${selectedProductIds.length}`)
        commit(SET_DELETED_PRODUCTS)
        commit(RESET_SELECTED_PRODUCTS)
      } catch (error) {
        throw new Error(error)
      }
    }
  }
})
