import { union } from 'lodash'

export default class Product {
  constructor(product, group, availabilities) {
    this.id = product.id
    this.name = product.name || group.name
    this.synonym = `${group.name} ${product.name}`
    this.description = group.description
    this.meta = this.synonym
      .concat(this.description)
      .replace(/[^a-zA-Z0-9]/g, '')
    this.price = product.basePrice / 100
    this.serviceTypes = product.availabilityIds.reduce((result, id) => {
      const { serviceTypes } = availabilities.find(item => item.id === id)
      return union(result, serviceTypes)
    }, [])
    this.isVisible = false
    this.isSelected = false
    this.isOffline = false
    this.isIndefinitelyOffline = false
    this.isDeleted = false
  }
  get hasMixedServiceTypes() {
    return this.serviceTypes.length > 1
  }
}
