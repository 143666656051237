import Availability from '../models/Availability'
import ProductsGroup from '../models/ProductsGroup'
import Category from '../models/Category'
export default class MenuService {
  static adaptApiMenuData({
    categories: apiCategories,
    items: apiGroups,
    availabilities: apiAvailabilities
  }) {
    const availabilities = apiAvailabilities.map(
      availability => new Availability(availability)
    )
    const groups = apiGroups.map(
      group => new ProductsGroup(group, availabilities)
    )
    return apiCategories.reduce((acc, apiCategory) => {
      const category = new Category(apiCategory, groups)
      if (!category.isHidden) {
        acc[category.uid] = category
      }
      return acc
    }, {})
  }
}
