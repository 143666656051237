import { TENANT } from '../constants'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const dayJsTimezone = require('dayjs/plugin/timezone')

const tenantsTimezone = {
  au: 'Australia/Sydney',
  es: 'Europe/Madrid',
  ie: 'Europe/Dublin',
  nz: 'Pacific/Auckland',
  it: 'Europe/Rome',
  uk: 'Europe/London'
}
const timeZone = tenantsTimezone[TENANT] || tenantsTimezone['uk']
// setup of Dayjs
dayjs
  .extend(utc)
  .extend(dayJsTimezone)
  .tz.setDefault(timeZone)

export function getNextAvailableAtDate() {
  // now in tenant timezone
  const now = dayjs().tz()
  // nextAvailableAt by default is 05:00 of today
  let nextAvailableAt = now.startOf('day').add(5, 'hour')
  // if now is after 05:00 of today nextAvailableAt will be 05:00 of tomorrow
  if (now.isAfter(nextAvailableAt)) {
    nextAvailableAt = nextAvailableAt.add(1, 'day')
  }
  // result as an ISO 8601 in GTM
  return nextAvailableAt.toISOString()
}
