<template>
  <div class="font-bold md:font-normal" data-cy="itemsSelectAll">
    <pc-checkbox
      v-model="allSelectedProducts"
      :label="$t('select_all')"
      data-cy="itemsSelectAllPicker"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { PcCheckbox } from '@je-pc/ui-components'
import {
  ADD_TO_SELECTED_PRODUCTS,
  RESET_SELECTED_PRODUCTS
} from '../store/mutations.types'

export default {
  components: {
    PcCheckbox
  },
  computed: {
    ...mapState({
      selectedProductIds: 'selectedProductIds'
    }),
    ...mapGetters({
      visibleProductIds: 'visibleProductIds'
    }),
    allSelectedProducts: {
      get() {
        return (
          this.visibleProductIds.length !== 0 &&
          this.visibleProductIds.every(id =>
            this.selectedProductIds.includes(id)
          )
        )
      },
      set(value) {
        value
          ? this.$store.commit(ADD_TO_SELECTED_PRODUCTS, this.visibleProductIds)
          : this.$store.commit(RESET_SELECTED_PRODUCTS)
      }
    }
  }
}
</script>
