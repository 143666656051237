const namespace = '@@pc-sticky-directive'
const events = [
  'resize',
  'scroll',
  'touchstart',
  'touchmove',
  'touchend',
  'pageshow',
  'load'
]

const batchStyle = (el, style = {}, classNames = [], isSticky) => {
  for (let k in style) {
    el.style[k] = style[k]
  }
  classNames.forEach(k => {
    if (isSticky && !el.classList.contains(k)) {
      el.classList.add(k)
    } else if (!isSticky && el.classList.contains(k)) {
      el.classList.remove(k)
    }
  })
}

class Sticky {
  constructor(el, vm) {
    this.el = el
    this.vm = vm
    this.unsubscribers = []
    this.isPending = false
    this.state = {
      height: null,
      width: null,
      xOffset: null,
      stickyClassName: this.getStickyClasses(),
      fullMode: this.getFullMode(),
      isSticky: false
    }
  }

  doBind() {
    if (this.unsubscribers.length > 0) {
      return
    }
    const { el, vm } = this
    vm.$nextTick(() => {
      this.placeholderEl = document.createElement('div')
      this.containerEl = this.getContainerEl()
      el.parentNode.insertBefore(this.placeholderEl, el)
      events.forEach(event => {
        const fn = this.update.bind(this)
        this.unsubscribers.push(() => window.removeEventListener(event, fn))
        window.addEventListener(event, fn, { passive: true })
      })
    })
  }

  doUnbind() {
    this.unsubscribers.forEach(fn => fn())
    this.unsubscribers = []
    this.resetElement()
  }

  update() {
    if (!this.isPending) {
      requestAnimationFrame(() => {
        this.isPending = false
        this.recomputeState()
        this.updateElements()
      })
      this.isPending = true
    }
  }

  isSticky() {
    return this.state.placeholderElRect?.top <= 0
  }

  recomputeState() {
    this.state = Object.assign({}, this.state, {
      height: this.getHeight(),
      width: this.getWidth(),
      xOffset: this.getXOffset(),
      placeholderElRect: this.getPlaceholderElRect(),
      containerElRect: this.getContainerElRect(),
      stickyClassName: this.getStickyClasses(),
      fullMode: this.getFullMode()
    })
    this.state.isSticky = this.isSticky()
  }

  updateElements() {
    const placeholderStyle = { paddingTop: 0 }
    const elStyle = {
      left: 'auto',
      width: 'auto'
    }
    const placeholderClassName = { 'pc-sticky-placeholder': true }

    if (this.state.isSticky) {
      if (this.state.fullMode) {
        elStyle.left = '0'
        elStyle.width = '100%'
      } else {
        elStyle.left = this.state.xOffset + 'px'
        elStyle.width = this.state.width + 'px'
      }
      placeholderStyle.paddingTop = this.state.height + 'px'
    }

    batchStyle(
      this.el,
      elStyle,
      this.state.stickyClassName,
      this.state.isSticky
    )
    batchStyle(this.placeholderEl, placeholderStyle, [placeholderClassName])
  }

  resetElement() {
    ;['left', 'width'].forEach(attr => {
      this.el.style.removeProperty(attr)
    })
    const { parentNode } = this.placeholderEl
    if (parentNode) {
      parentNode.removeChild(this.placeholderEl)
    }
  }

  getContainerEl() {
    let node = this.el.parentNode
    while (
      node &&
      node.tagName !== 'HTML' &&
      node.tagName !== 'BODY' &&
      node.nodeType === 1
    ) {
      if (node.hasAttribute('sticky-container')) {
        return node
      }
      node = node.parentNode
    }
    return this.el.parentNode
  }

  getXOffset() {
    return this.placeholderEl.getBoundingClientRect().left
  }

  getWidth() {
    return this.placeholderEl.getBoundingClientRect().width
  }

  getHeight() {
    return this.el.getBoundingClientRect().height
  }

  getPlaceholderElRect() {
    return this.placeholderEl.getBoundingClientRect()
  }

  getContainerElRect() {
    return this.containerEl.getBoundingClientRect()
  }

  getStickyClasses() {
    return this.getAttribute('sticky-classes')?.split(' ')
  }

  getFullMode() {
    return this.getAttribute('sticky-full')
  }

  getAttribute(name) {
    const expr = this.el.getAttribute(name)
    let result = undefined
    if (expr) {
      if (this.vm[expr]) {
        result = this.vm[expr]
      } else {
        try {
          result = eval(`(${expr})`)
        } catch (error) {
          result = expr
        }
      }
    }
    return result
  }
}

export default {
  inserted(el, bind, vnode) {
    if (typeof bind.value === 'undefined' || bind.value) {
      el[namespace] = new Sticky(el, vnode.context)
      el[namespace].doBind()
    }
  },
  unbind(el) {
    if (el[namespace]) {
      el[namespace].doUnbind()
      el[namespace] = undefined
    }
  },
  componentUpdated(el, bind, vnode) {
    if (typeof bind.value === 'undefined' || bind.value) {
      if (!el[namespace]) {
        el[namespace] = new Sticky(el, vnode.context)
      }
      el[namespace].doBind()
    } else {
      if (el[namespace]) {
        el[namespace].doUnbind()
      }
    }
  }
}
