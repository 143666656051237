<template>
  <div class="items-control-confirmation" data-cy="itemsControlConfirmation">
    <!-- MOBILE: show confirmation message as part of control block -->
    <div v-if="isMobile" class="p-6" data-cy="itemsControlConfirmationMobile">
      <slot data-cy="itemsControlConfirmationContent"></slot>
    </div>
    <!-- DESKTOP: show confirmation message as modal -->
    <pc-modal
      v-else
      v-model="isVisible"
      size="regular"
      :closable="false"
      class="z-20"
      data-cy="itemsControlConfirmationModal"
    >
      <template slot="content">
        <slot data-cy="itemsControlConfirmationContent"></slot>
      </template>
    </pc-modal>
  </div>
</template>

<script>
import { PcModal, isMobile } from '@je-pc/ui-components'

export default {
  props: {
    show: {
      type: Boolean
    }
  },
  mixins: [isMobile],
  components: {
    PcModal
  },
  computed: {
    isVisible: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('close', value)
      }
    }
  }
}
</script>
