<template>
  <div
    class="z-10 bg-white shadow-inverse sm:shadow-none"
    :class="[
      isMobile
        ? 'transform transition-all ease-linear fixed z-10 duration-1000 bottom-0 left-0 right-0'
        : '',
      isMobileControlVisible ? 'translate-y-0' : 'translate-y-full'
    ]"
    data-cy="itemsControl"
  >
    <!-- items control confirmation wrapper -->
    <items-control-confirmation
      v-show="isConfirmationVisible"
      :show="isConfirmationVisible"
      @close="hideConfirmation"
    >
      <!-- items control confirmation content for delete items -->
      <div class="text-xl sm:text-2xl font-bold mb-4">
        {{ $t('delete_confirmation_title') }}
      </div>
      <div class="mb-6">
        {{ $t('delete_confirmation_message') }}
      </div>
      <div class="flex justify-end space-x-2">
        <pc-button
          type="ghost"
          @click="hideConfirmation"
          data-cy="itemsControlConfirmationCancel"
        >
          {{ $t('button_cancel') }}
        </pc-button>
        <pc-button
          @click="deleteItems"
          :loading="isDeleteLoading"
          data-cy="itemsControlConfirmationDelete"
        >
          {{ $t('button_delete') }}
        </pc-button>
      </div>
    </items-control-confirmation>
    <!-- items control buttons -->
    <items-control-buttons
      v-show="isControlButtonVisible"
      @items-delete="showConfirmation"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PcButton, isMobile } from '@je-pc/ui-components'
import { DELETE_ITEMS } from '../../store/actions.types'
import { successHandler, errorHandler } from '../../services/responseHandler'
import ItemsControlButtons from './ItemsControlButtons'
import ItemsControlConfirmation from './ItemsControlConfirmation'

export default {
  components: {
    PcButton,
    ItemsControlButtons,
    ItemsControlConfirmation
  },
  mixins: [isMobile],
  data() {
    return {
      isConfirmationVisible: false,
      isDeleteLoading: false
    }
  },
  computed: {
    ...mapGetters({
      isProductsSelected: 'isProductsSelected'
    }),
    isMobileControlVisible() {
      return this.isMobile && this.isProductsSelected
    },
    isControlButtonVisible() {
      if (!this.isMobile) return true
      return !this.isConfirmationVisible
    }
  },
  methods: {
    showConfirmation() {
      this.isConfirmationVisible = true
    },
    hideConfirmation() {
      this.isConfirmationVisible = false
    },
    async deleteItems() {
      this.isDeleteLoading = true
      try {
        await this.$store.dispatch(DELETE_ITEMS)
        this.hideConfirmation()
        successHandler({
          message: this.$t('deleted_successfully_message')
        })
      } catch (error) {
        errorHandler()
      }
      this.isDeleteLoading = false
    }
  }
}
</script>
