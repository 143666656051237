import { justLog, BrowserContext } from '@justeat/just-log'
import Vue from 'vue'
import VueJustLog, { withRouteLogging } from '@justeat/vue-just-log'
import { v4 as uuidv4 } from 'uuid'
import { getRestaurantId } from '@je-pc/auth'
import router from './router'
import { supportedBrowsers } from './supportedBrowsers.generated'
import { LOGGING_URL, TENANT, ENVIRONMENT, FEATURE } from './constants.js'
const isDev = !ENVIRONMENT.includes('production')

export const sessionCorrelationId = uuidv4()
export const initialiseLogging = () => {
  if (!LOGGING_URL) return

  const isBrowserSupported = supportedBrowsers.test(navigator.userAgent)

  Vue.use(VueJustLog, {
    api: LOGGING_URL,
    tenant: TENANT,
    environment: ENVIRONMENT,
    name: FEATURE,
    context() {
      return {
        url: window.location.href,
        restaurantId: getRestaurantId(),
        isBrowserSupported
      }
    },
    logToConsole: isDev || false
  })
  justLog
    .withContext(new BrowserContext())
    .withCorrelationId(() => sessionCorrelationId)

  withRouteLogging(router)
}
