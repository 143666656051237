import { ApiClient } from '@je-pc/api'
import { chunk } from 'lodash'
import { SMARTGATEWAY_URL } from '../constants'
import MenuService from './menu'
import { justLog } from '@justeat/just-log'

const http = new ApiClient(SMARTGATEWAY_URL, true, true)
http.client.interceptors.response.use(
  response => {
    const statusCode = response?.status
    const statusText = response?.statusText
    const method = response.config?.method?.toUpperCase()
    const url = response.config.url
    justLog.info(
      `Successful HTTP call: received ${statusCode} (${statusText}) response from '${method} ${url}'`,
      {
        method,
        url,
        statusCode
      }
    )
    return response
  },
  error => {
    const response = error?.response
    const statusCode = response?.status
    const statusText = response?.statusText
    const config = error?.config
    const method = config?.method?.toUpperCase()
    const url = config?.url
    const responseBody = response?.request?.responseText
    if (response) {
      justLog.error(
        `Failed HTTP call: received ${statusCode} (${statusText}) response from '${method} ${url}'`,
        {
          method,
          url,
          statusCode,
          responseBody
        }
      )
    } else {
      justLog.error(
        `Failed HTTP call: received no response from attempt to call '${method} ${url}'`,
        {
          method,
          url
        }
      )
    }

    return Promise.reject(error)
  }
)

export const fetchItemAvailability = async () => {
  const response = await http.get('/restaurants/{tenant}/{restaurantId}/menu')
  return MenuService.adaptApiMenuData(response.data)
}

export const getOfflineItems = async (cursorAfter = null, limit) => {
  let totalOfflineItems = []
  const response = await http.get(
    '/restaurants/{tenant}/{restaurantId}/catalogue/offline-items',
    { params: { limit, cursor: cursorAfter } }
  )
  const { offlineItems, cursor } = response.data
  totalOfflineItems = totalOfflineItems.concat(offlineItems)
  if (cursor.after) {
    const items = await getOfflineItems(cursor.after)
    return totalOfflineItems.concat(items)
  }
  return totalOfflineItems
}

export const getSettings = params => {
  return http.get(
    `/applications/partnercentre/{tenant}/{restaurantId}/settings?settings=${params}`
  )
}

export const deleteItems = params => {
  return http.delete(
    '/applications/partnercentremenumanagement/restaurants/{tenant}/{restaurantId}/catalogue/items',
    params
  )
}

// request has limits - Variation maximum sum of 25 Variation IDs
let markItemsAsOnline = (itemIds = []) => {
  return http.post(
    '/restaurants/{tenant}/{restaurantId}/catalogue/online-item-requests',
    { variationIds: itemIds }
  )
}
// request has limits - Variation maximum sum of 25 Variation IDs
let markItemsAsOffline = (itemIds = [], nextAvailableAt) => {
  return http.post(
    '/restaurants/{tenant}/{restaurantId}/catalogue/offline-item-requests',
    { variationIds: itemIds, nextAvailableAt }
  )
}

const chunkItemIdsDecorator = (handler, chunkSize = 25) => {
  return function(itemIds, ...restArgs) {
    const chunksItemIds = chunk(itemIds, chunkSize)
    return Promise.all(
      chunksItemIds.map(
        async chunkItemIds => await handler(chunkItemIds, ...restArgs)
      )
    )
  }
}

markItemsAsOnline = chunkItemIdsDecorator(markItemsAsOnline)
markItemsAsOffline = chunkItemIdsDecorator(markItemsAsOffline)

export { markItemsAsOnline, markItemsAsOffline }
