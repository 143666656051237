export default class Category {
  static NOT_SHOWN = 'NOT_SHOWN'

  constructor(category, groups = []) {
    this.id = category.id
    this.name = category.name
    this.description = category.description
    this.productsGroups = groups.filter(group =>
      category.itemIds.includes(group.id)
    )
    this.isVisible = false
  }
  get uid() {
    return `${this.id}_${this.name}`.replace(/ /g, '_')
  }
  get isHidden() {
    return this.name.toUpperCase() === Category.NOT_SHOWN
  }
}
