import Product from './Product'

export default class ProducsGroup {
  constructor(group, availabilities) {
    this.id = group.id
    this.name = group.name
    this.description = group.description
    this.products = group.variations.map(
      product => new Product(product, group, availabilities)
    )
    this.isVisible = false
    this.isSelected = false
    this.hasSingleProduct = this.products.length === 1
  }
  get hasMixedServiceType() {
    if (this.products.some(product => product.hasMixedServiceTypes)) return true
    return this.products
      .map(product => product.serviceTypes)
      .some((serviceTypes, i, [baseServicesType]) => {
        return serviceTypes[0] !== baseServicesType[0]
      })
  }
}
