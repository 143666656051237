<template>
  <ul data-cy="categoriesList">
    <li
      v-for="category in filteredCategories"
      :key="category.uid"
      data-cy="categoriesListItem"
    >
      <a
        href="javascript:void(0)"
        class="px-4 py-3 leading-none block"
        :class="getCategoryClasses(category)"
        @click.prevent="selectCategory(category)"
        data-cy="categoriesListItemLink"
      >
        {{ category.name }}
      </a>
    </li>
  </ul>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { SET_ACTIVE_CATEGORY_ID } from '../store/mutations.types'

export default {
  computed: {
    ...mapState({
      activeCategoryId: 'activeCategoryId'
    }),
    ...mapGetters({
      filteredCategories: 'filteredCategories',
      visibleCategories: 'visibleCategories'
    }),
    isActiveCategoryVisible() {
      const category = this.visibleCategories.find(
        category => category.id === this.activeCategoryId
      )
      return category?.isVisible
    }
  },
  methods: {
    getCategoryClasses(category) {
      const { id, isVisible } = category
      if (!isVisible) {
        return 'text-grey-45 cursor-not-allowed'
      }
      if (this.isCategoryActive(id)) {
        return 'bg-orange-10 font-bold'
      }
      return ''
    },
    isCategoryActive(id) {
      return id === this.activeCategoryId
    },
    selectCategory(category) {
      const { id, isVisible } = category
      if (!isVisible) return
      this.$store.commit(SET_ACTIVE_CATEGORY_ID, id)
    }
  },
  watch: {
    isActiveCategoryVisible: {
      handler(isVisible) {
        if (isVisible) return
        const [category] = this.visibleCategories
        category && this.selectCategory(category)
      },
      immediate: true
    }
  }
}
</script>
