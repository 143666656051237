<template>
  <div
    class="flex items-center relative w-full overflow-hidden bg-white"
    :class="{ 'border border-solid border-grey-30': isMobile }"
    data-cy="itemsSearch"
  >
    <input
      class="
        w-full
        ml-4 sm:ml-0 pr-4 pl-8 py-3 sm:py-2
        focus:outline-none box-border placeholder-grey-50"
      :placeholder="$t('search_placeholder')"
      type="text"
      v-model="search"
      data-cy="itemsSearchInput"
    />
    <icon-search
      class="w-6 h-6 absolute left-0 ml-4 sm:ml-0"
      data-cy="itemsSearchIcon"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { SET_SEARCH_VALUE } from '../store/mutations.types'
import { isMobile } from '@je-pc/ui-components'
import IconSearch from '../icons/IconSearch'

export default {
  components: {
    IconSearch
  },
  data() {
    return {
      search: '',
      searchTimeoutId: 0
    }
  },
  mixins: [isMobile],
  computed: {
    ...mapState({
      searchValue: 'searchValue'
    })
  },
  watch: {
    search() {
      clearTimeout(this.searchTimeoutId)
      this.searchTimeoutId = setTimeout(() => {
        const preparedSearch = this.search.trim()
        this.$store.commit(SET_SEARCH_VALUE, preparedSearch)
      }, 300)
    }
  }
}
</script>
