<template>
  <div class="text-center px-8 pb-12 m-auto sm:w-6/12 sm:pt-8">
    <component class="empty-state-icon m-auto" :is="activeStateType.icon" />
    <h3 class="mb-2">{{ $t(activeStateType.title) }}</h3>
    <i18n :path="activeStateType.text" tag="p" class="px-2">
      <button
        class="text-blue-100 font-bold underline lowercase hover:no-underline"
        @click="changeFilterType"
      >
        {{ $t(isSelectedOnlineFilter ? 'filter_off_menu' : 'filter_on_menu') }}
      </button>
    </i18n>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import IconEmptyOfflineList from '../../icons/IconEmptyOfflineList.vue'
import IconEmptyOnlineList from '../../icons/IconEmptyOnlineList.vue'
import IconEmptySearchedList from '../../icons/IconEmptySearchedList.vue'

import {
  SET_IS_SELECTED_ONLINE_FILTER,
  RESET_SELECTED_PRODUCTS
} from '../../store/mutations.types'

export default {
  data() {
    return {
      types: {
        emptySearchedList: {
          title: 'no_matching_items',
          text: 'try_another_search',
          icon: 'IconEmptySearchedList'
        },
        emptyOnlineList: {
          title: 'all_items_offline',
          text: 'empty_online_list_text',
          icon: 'IconEmptyOnlineList'
        },
        emptyOfflineList: {
          title: 'all_items_online',
          text: 'empty_offline_list_text',
          icon: 'IconEmptyOfflineList'
        }
      }
    }
  },
  components: {
    IconEmptyOfflineList,
    IconEmptyOnlineList,
    IconEmptySearchedList
  },
  computed: {
    ...mapState({
      isSelectedOnlineFilter: state => state.isSelectedOnlineFilter,
      searchValue: state => state.searchValue
    }),
    stateType() {
      if (this.searchValue) {
        return 'emptySearchedList'
      }
      return this.isSelectedOnlineFilter
        ? 'emptyOnlineList'
        : 'emptyOfflineList'
    },
    activeStateType() {
      return this.types[this.stateType]
    }
  },
  methods: {
    changeFilterType() {
      this.$store.commit(
        SET_IS_SELECTED_ONLINE_FILTER,
        !this.isSelectedOnlineFilter
      )
      this.$store.commit(RESET_SELECTED_PRODUCTS)
    }
  }
}
</script>

<style scoped>
.empty-state-icon {
  width: 9rem;
  height: 9rem;
}

@media screen and (min-width: 640px) {
  .empty-state-icon {
    width: 12.5rem;
    height: 12.5rem;
  }
}
</style>
