export default {
  numberFormat: {
    currency: {
      style: 'currency',
      currency: 'GBP'
    }
  },
  messages: {
    item_availability: 'Item availability',
    heading_description:
      'Temporarily take items off your menu until the end of the day if you’re running low or out of stock.',
    heading_menu_edit_rmm: 'Use the menu tool to {0}',
    heading_menu_edit_rmm_link: 'add, edit or remove items from your menu',
    heading_menu_edit_form: 'To update your standard menu, please {0}',
    heading_menu_edit_form_link: 'complete a change request form.',
    heading_menu_edit_by_email: '',
    search_placeholder: 'Search for dish or ingredient',
    delete_items: 'Delete item(s)',
    remove_items: 'Take off menu',
    removed_items_successfully_message: 'Item(s) taken off menu until tomorrow',
    restore_items: 'Put back on menu',
    restored_items_successfully_message: 'Item(s) back on your menu',
    select_all: 'Select all items',
    view_items: 'View items',
    filter_on_menu: 'On menu now',
    filter_off_menu: 'Off menu',
    button_delete: 'Delete',
    button_cancel: 'Cancel',
    delete_confirmation_title: 'Delete item(s)',
    delete_confirmation_message:
      'Are you sure you want to delete these selected items from your menu?',
    deleted_successfully_message: 'Item(s) deleted',
    delivery: 'Delivery items only',
    collection: 'Collection only',
    back_tomorrow: 'Back tomorrow',
    off_menu_indefinitely: 'Off menu indefinitely',
    no_matching_items: 'No matching items found',
    all_items_online: 'All items are on your menu',
    all_items_offline: 'All items are off menu',
    try_another_search: 'Try another search, or view items {0}',
    empty_offline_list_text:
      "If you'd like to set some items to be off your menu, view items {0}",
    empty_online_list_text:
      "If you'd like to put some items back on your menu now, view items {0}",
    generic_error: 'Something went wrong. Please try again.'
  }
}
