<template>
  <span
    v-show="label"
    class="font-normal text-xs px-2
    rounded-sm text-grey-100
    inline-flex items-center h-4"
  >
    {{ label }}
  </span>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>
