import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      searchValue: state => state.searchValue
    })
  },
  methods: {
    getMarkedString(string) {
      if (this.searchValue === '') return string
      const regex = new RegExp(this.searchValue, 'gi')
      return string.replace(
        regex,
        str => '<mark class="bg-orange-10">' + str + '</mark>'
      )
    }
  }
}
