<template>
  <div data-cy="itemsList">
    <pc-collapse
      v-for="category in filteredCategories"
      v-show="category.isVisible"
      :key="category.uid"
      :ref="category.id"
      :header-text="category.name"
      class="items-list-category bg-white md:px-4 lg:px-12 mb-4 sm:mb-6 mt-0 sm:mt-6 md:mt-8"
      data-cy="itemsListCategory"
    >
      <div data-cy="itemsListCategoryCollapse">
        <!-- items list category desktop title and description -->
        <div
          class="hidden sm:block mb-4 sm:mb-6 mt-0 sm:mt-6 md:mt-8 sm:px-4 md:px-0"
        >
          <div
            class="flex justify-between  items-center md:text-2xl font-bold leading-snug"
            data-cy="itemsListCategoryName"
          >
            {{ category.name }}
          </div>
          <div
            class="text-sm text-grey-50 hidden sm:block"
            data-cy="itemsListCategoryDescription"
          >
            {{ category.description }}
          </div>
        </div>
        <items-list-group
          v-for="group in category.productsGroups"
          v-show="group.isVisible"
          :key="group.id"
          :group="group"
        />
      </div>
    </pc-collapse>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { PcCollapse } from '@je-pc/ui-components'
import ItemsListGroup from './ItemsListGroup'

export default {
  components: {
    PcCollapse,
    ItemsListGroup
  },
  computed: {
    ...mapState({
      activeCategoryId: 'activeCategoryId'
    }),
    ...mapGetters({
      filteredCategories: 'filteredCategories'
    })
  },
  watch: {
    activeCategoryId(categoryId) {
      if (!categoryId) return
      const [category] = this.$refs[categoryId] ?? []
      if (!category) return
      const top = category.$el.offsetTop
      window.scrollTo({ top, behavior: 'smooth' })
    }
  }
}
</script>
