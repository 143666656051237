<template>
  <div
    class="my-3.5 flex justify-between items-top"
    :class="{ 'text-grey-45': isOffline }"
    data-cy="itemsListProduct"
  >
    <div class="flex flex-col sm:flex-row sm:items-center">
      <pc-checkbox
        :label="getMarkedString(product.name)"
        v-model="isSelected"
        data-cy="itemsListProductPicker"
        @change="trackItemSelection"
      />
      <items-list-badge
        :label="statusTypeBadge"
        :class="[isIndefinitelyOffline ? 'bg-yellow-10' : 'bg-grey-20']"
        class="bg-grey-20 ml-6 sm:ml-3 mt-2 sm:mt-0"
        data-cy="itemsListProductStatusBadge"
      />
      <items-list-badge
        :label="serviceTypeBadge"
        class="bg-blue-10 ml-6 sm:ml-3 mt-2 sm:mt-0"
        data-cy="itemsListProductServiceBadge"
      />
    </div>
    <span data-cy="itemsListProductPrice">
      {{ $n(product.price, 'currency') }}
    </span>
  </div>
</template>

<script>
import { trackSelection } from '../../services/gtm'
import {
  ADD_TO_SELECTED_PRODUCTS,
  REMOVE_FROM_SELECTED_PRODUCTS
} from '../../store/mutations.types'
import ProductMixin from '../../mixins/product'
import SearchMarkMixin from '../../mixins/searchMark'

export default {
  mixins: [ProductMixin, SearchMarkMixin],
  props: {
    product: {
      required: true
    },
    isVisibleStatusType: {
      type: Boolean,
      default: false
    },
    isVisibleServiceType: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    productId() {
      return this.product.id
    },
    isSelected: {
      get() {
        return this.product.isSelected
      },
      set(isSelected) {
        isSelected
          ? this.$store.commit(ADD_TO_SELECTED_PRODUCTS, [this.productId])
          : this.$store.commit(REMOVE_FROM_SELECTED_PRODUCTS, [this.productId])
      }
    },
    isOffline() {
      return this.product.isOffline
    },
    isIndefinitelyOffline() {
      return this.product.isIndefinitelyOffline
    },
    //product badges logic
    statusTypeBadge() {
      if (!this.isVisibleStatusType) return ''
      if (this.isIndefinitelyOffline) return this.$t('off_menu_indefinitely')
      if (this.isOffline) return this.$t('back_tomorrow')
      return ''
    },
    serviceTypeBadge() {
      if (
        !this.isVisibleServiceType ||
        !this.menuHasMixedServiceTypes ||
        this.product.hasMixedServiceTypes
      )
        return ''
      return this.$t(this.product.serviceTypes[0])
    }
  },
  methods: {
    trackItemSelection(value) {
      trackSelection(value, this.product.id)
    }
  }
}
</script>
