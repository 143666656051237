<template>
  <div
    class="flex flex-col-reverse sm:flex-row
      items-center justify-center sm:justify-start md:justify-end
      w-full p-4 sm:p-0"
    data-cy="itemsControlButtons"
  >
    <pc-button
      v-if="isDeleteEnabled"
      :disabled="!isProductsSelected"
      @click="handleDelete"
      type="ghost"
      class="w-full sm:w-auto"
      data-cy="itemsControlButtonDelete"
    >
      <span class="flex items-center">
        <icon-delete class="mr-4" />
        {{ $t('delete_items') }}
      </span>
    </pc-button>
    <pc-button
      v-if="isSelectedOnlineFilter"
      class="w-full sm:w-auto mb-2 sm:mb-0 sm:ml-2 "
      :disabled="!isRemoveEnabled"
      :loading="isRemoveLoading"
      @click="handleRemove"
      data-cy="itemsControlButtonRemove"
    >
      {{ $t('remove_items') }}
    </pc-button>
    <pc-button
      v-else
      class="w-full sm:w-auto mb-2 sm:mb-0 sm:ml-2"
      :disabled="!isRestoreEnabled"
      :loading="isRestoreLoading"
      @click="handleRestore"
      data-cy="itemsControlButtonRestore"
    >
      {{ $t('restore_items') }}
    </pc-button>
  </div>
</template>

<script>
import { PcButton } from '@je-pc/ui-components'
import {
  MARK_ITEMS_AS_ONLINE,
  MARK_ITEMS_AS_OFFLINE
} from '../../store/actions.types'
import { errorHandler, successHandler } from '../../services/responseHandler'
import IconDelete from '../../icons/IconDelete'
import { TENANT } from '../../constants'

export default {
  components: {
    IconDelete,
    PcButton
  },
  data() {
    return {
      isRemoveLoading: false,
      isRestoreLoading: false
    }
  },
  computed: {
    isMenulogBrand() {
      return TENANT === 'au' || TENANT === 'nz'
    },
    isProductsSelected() {
      return this.$store.getters.isProductsSelected
    },
    isDeleteEnabled() {
      return (
        !this.$store.state.settings.isMenuSyncEnabled && !this.isMenulogBrand
      )
    },
    isSelectedOnlineFilter() {
      return this.$store.state.isSelectedOnlineFilter
    },
    isRestoreEnabled() {
      return this.isProductsSelected && !this.isSelectedOnlineFilter
    },
    isRemoveEnabled() {
      return this.isProductsSelected && this.isSelectedOnlineFilter
    }
  },
  methods: {
    handleDelete() {
      this.$emit('items-delete')
    },
    async handleRemove() {
      this.isRemoveLoading = true
      try {
        await this.$store.dispatch(MARK_ITEMS_AS_OFFLINE)
        successHandler({
          message: this.$t('removed_items_successfully_message')
        })
      } catch (error) {
        errorHandler()
      }
      this.isRemoveLoading = false
    },
    async handleRestore() {
      this.isRestoreLoading = true
      try {
        await this.$store.dispatch(MARK_ITEMS_AS_ONLINE)
        successHandler({
          message: this.$t('restored_items_successfully_message')
        })
      } catch (error) {
        errorHandler()
      }
      this.isRestoreLoading = false
    }
  }
}
</script>
